<template>
  <article class="view-week-questions">
    <div class="site-wrapper rythm-v-l">
      <header class="page-header">
        <h1>
          Questions de la semaine
        </h1>
      </header>

      <section class="section--question rythm-v">
        <div class="toolbar" data-toolbar="week-questions">
          <label for="select-week">Semaine : </label>
          <select name="select-week" class="vgt-select" @change="selectWeekForWeekQuestions">
            <option :value="selectedWeek" v-for="selectedWeek in weeks" :selected="selectedWeek == week">Semaine {{ selectedWeek }} {{ getFormattedWeekDates(selectedWeek) }}</option>
          </select>

          <label for="select-year">Année : </label>
          <select name="select-year" class="vgt-select" @change="selectYearForWeekQuestions">
            <option :value="selectedYear" v-for="selectedYear in years" :selected="selectedYear == year">{{ selectedYear }}</option>
          </select>
        </div>

        <section class="section--week-questions-details">
          <div class="card shadow empty-state flex-col flex-center flex-item--100" v-show="!weekQuestions.length">
            <span>Aucune question pour le moment</span>
          </div>

          <div class="card shadow question-table-wrapper flex-item--100" v-show="weekQuestions.length">
            <vue-good-table
              ref="weekQuestionsTable"
              :columns="columnsTitles"
              :rows="weekQuestions"
              styleClass="vgt-table striped"
              mode="remote"
              :paginate="true"
              :pagination-options="paginationOptions"
              :totalRows="totalRecords"
              @on-page-change="onPageChange"
              @on-per-page-change="onPerPageChange"
            >
              <template slot="table-column" slot-scope="props">
                <span>{{ props.column.label }}</span>
              </template>

              <template slot="table-row" slot-scope="props">
                <div class="question-title-wrap flex-row flex-center-v" v-if="props.column.field == 'title'">
                  <details class="week-question-excerpt" :ref="`excerpt${props.row.id}`" :data-excerpt="props.row.id" @click="excerptToggle">
                    <summary class="details-title">
                      <span>{{ props.formattedRow[props.column.field] }}</span>
                    </summary>
                    <div class="details-body">
                      <div class="week-question-details">
                        <div class="message" v-html="props.row.details"></div>
                        <footer>
                          <div class="user-card">
                            <header>{{ props.row.user.name }}</header>
                          </div>
                          <div class="links">
                            <router-link class="flex-item--grow" :to="`/question/${props.row.id}`">Afficher la question</router-link>
                            <button type="button" class="button--link" @click.prevent="toggleMailtoModal(props.row)">Demande d'échange</button>
                          </div>
                        </footer>
                      </div>
                      <ul>
                        <li v-for="answer in props.row.answers" class="week-question-answer">
                          <div class="message" v-html="answer.details"></div>
                          <div class="user-card">
                            <header>{{ answer.user }}</header>
                            <div>- {{ answer.date_modified }}</div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </details>
                </div>

                <!-- Any other column (number, date, …) -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <div slot="emptystate">
                <p class="text-center">
                  Aucune question ne correspond aux filtres sélectionnés.
                </p>
              </div>
            </vue-good-table>
          </div>
        </section>
      </section>
    </div>
    <mailto-modal :open="modalMailtoVisible" v-if="currentQuestion" :key="currentQuestion.id" :question="currentQuestion" @modalClose="modalClose" />
  </article>
</template>

<style lang="scss">
@import "@/scss/week-questions.scss";
</style>

<script>
import { HTTP } from "../http-common.js";

// Vue Good Table
import { VueGoodTable } from "vue-good-table";

import MailtoModal from "@/components/MailtoModal.vue";
import { log } from "logrocket";

// Component
export default {
  name: "WeekQuestions",
  props: {
    year: {
      default() {
        let year = new Date();
        return year.getFullYear();
      },
    },
    week: {
      default() {
        let week = this.$dayjs().isoWeek();

        return week;
      },
    },
  },
  components: {
    VueGoodTable,
    MailtoModal,
  },

  data: function() {
    return {
      API_URL: HTTP.defaults.baseURL,
      userToken: this.$store.state.userToken,
      weekQuestions: [],
      currentQuestion: null,

      // Modals status
      modalMailtoVisible: false,

      columnsTitles: [
        {
          label: "Titre de la question",
          field: "title",
          sortable: false,
          tdClass: "",
        },
        {
          label: "Date",
          field: "date_created",
          type: "date",
          dateInputFormat: "yyyy-MM-dd H:mm:s",
          dateOutputFormat: "dd/MM/yyyy H:mm",
          sortable: false,
          tdClass: "justify-top",
          width: "200px",
        },
        {
          label: "Affectation",
          field: "expert",
          sortable: false,
          tdClass: " justify-top",
          width: "400px",
        },
      ],
      // Table server mode
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: "",
            type: "",
          },
        ],
        page: 1,
        perPage: 20,
      },
      // Table pagination
      paginationOptions: {
        enabled: true,
        nextLabel: "suivant",
        prevLabel: "précédent",
        rowsPerPageLabel: "lignes par page",
        ofLabel: "sur",
        perPage: 20,
        pageLabel: "page", // for 'pages' mode
        allLabel: "Toutes",
      },
    };
  },

  computed: {
    // Current user data
    user: function() {
      return this.$store.state.userData;
    },

    // Is the user an admin? (role 4)
    user_is_admin: function() {
      return this.user.roles.indexOf(4) !== -1;
    },

    // Is the user a coordinator? (role 3)
    user_is_coordinator: function() {
      return this.user.roles.indexOf(3) !== -1;
    },

    // Is the user an expert? (role 2)
    user_is_expert: function() {
      return this.user.roles.indexOf(2) !== -1;
    },

    // Is the user an expert extended? (role 5)
    user_is_expert_ext: function() {
      return this.user.roles.indexOf(5) !== -1;
    },

    // Is a regular user?
    user_is_regular: function() {
      let r = this.user.roles.reduce((acc, val, i, roles) => acc + parseInt(val));
      return r < 2;
    },

    // Récupère la liste des années depuis la mise en ligne de l'appli
    years: function() {
      const applicationStartYear = 2021;
      let currentDate = new Date();
      let years = [];
      for (let i = applicationStartYear; i <= currentDate.getFullYear(); i++) {
        years.push(i);
      }

      return years;
    },

    weeks: function() {
      let weeksCount = this.$dayjs(this.year + "-01-01").isoWeeksInYear();

      return weeksCount;
    },
  },

  methods: {
    // Récupération des questions par semaine
    getWeekQuestions: function() {
      let year = this.year;
      let week = this.week;
      let page = this.serverParams.page;
      let perPage = this.serverParams.perPage;

      this.$store
        .dispatch("GET_WEEK_QUESTIONS", { year, week, page, perPage })
        .then((payload) => {
          if (!payload || !Object.entries(payload).length) {
            payload.weekQuestions = [];
            payload.totalRecords = 0;
          }

          this.weekQuestions = payload.weekQuestions;
          this.totalRecords = payload.totalRecords ? payload.totalRecords : 0;
        })
        .catch((error) => {
          console.error("getWeekQuestions error", error);
        });
    },

    // on modifie l'url lorsque l'on selectionne une semaine
    selectWeekForWeekQuestions: function(event) {
      let selectedWeek = event.target.value;
      this.$router.push(`/week-questions/${this.year}/${selectedWeek}`);
    },

    // on modifie l'url lorsque l'on selectionne une année
    selectYearForWeekQuestions: function(event) {
      let selectedYear = event.target.value;
      this.$router.push(`/week-questions/${selectedYear}/${this.week}`);
    },

    excerptToggle(e) {
      let details = e.target.closest("details");
      let allDetails = this.$refs.weekQuestionsTable.$el.querySelectorAll("[data-excerpt]");
      allDetails.forEach((elt) => {
        if (elt.getAttribute("data-excerpt") !== details.getAttribute("data-excerpt")) elt.removeAttribute("open");
      });
    },

    // Récupère les dates formattées correspondantes à un numéro de semaine
    getFormattedWeekDates: function(weekNumber) {
      // pour avoir les dates correspondant a 1 semaine donnée
      // on prend une date de l'année que l'on veut et on force le numero de semaine
      // on ne prend pas le 1er janvier car la semaine 1 ne démarre pas forcement au premier janvier
      let week = this.$dayjs(this.year + "-02-01").isoWeek(weekNumber);

      let firstDayOfWeek = week.weekday(0);
      let lastDayOfWeek = firstDayOfWeek.add(6, "day");
      let formattedWeekDates = "du " + firstDayOfWeek.format("DD/MM") + " au " + lastDayOfWeek.format("DD/MM");

      return formattedWeekDates;
    },

    // toggle mailto modal
    toggleMailtoModal: function(question) {
      this.currentQuestion = question;

      // Close modal
      if (this.modalMailtoVisible === true) {
        this.modalMailtoVisible = false;
        return;
      } else {
        this.modalMailtoVisible = true;
      }
    },

    // close modal
    modalClose: function(event) {
      this.modalMailtoVisible = false;
    },

    // Changement de page de la table
    onPageChange(params) {
      // save pagination preferences
      this.$store.dispatch("SAVE_WEEK_QUESTIONS_CURRENT_PAGE", params.currentPage);

      // update params and reload questions
      this.updateParams({ page: params.currentPage });

      this.getWeekQuestions(); // vérifier si nécessaire
    },

    // Changement du nombre de lignes par page de la table
    onPerPageChange(params) {
      // save pagination preferences
      this.$store.dispatch("SAVE_WEEK_QUESTIONS_CURRENT_PER_PAGE", params.currentPerPage);

      // update params and reload questions
      this.updateParams({ perPage: params.currentPerPage });

      this.getWeekQuestions(); // vérifier si nécessaire
    },

    updateParams(newProps) {
      console.log("updateParams");
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
  },

  mounted() {
    // Not for regular users
    if (this.user_is_regular) {
      this.$router.push("/dashboard");
    }

    // Load pagination preferences
    this.paginationOptions = { ...this.paginationOptions, ...this.$store.state.currentWeekQuestionsPaginationOptions };

    // Display last page
    if (this.paginationOptions.savedCurrentPage) {
      this.updateParams({ page: this.paginationOptions.savedCurrentPage });
      delete this.paginationOptions.savedCurrentPage;
    }

    // Load week questions
    this.getWeekQuestions();
  },

  watch: {
    $route(to, from) {
      // react to route changes...
      this.getWeekQuestions();
    },
  },
};
</script>
